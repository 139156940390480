<template>
    <div class="mt-3">
        <inner-page-card route-path="/auctions-list/All/" heading="Auction Details"
                         :link-back="true" :scrollbar="true">

            <div class="container" v-if="loading">
                <div class="card bg-info">
                    <loading-animation/>
                    Please wait while loading data from the server.
                </div>
            </div>

            <div class="container" v-else-if="error">
                <div class="card bg-danger fl-te-c">
                    <div>
                        <error-icon/>
                        Failed to load data from the server.
                    </div>
                    <div>
                        <a class="pointer" @click="loadData()"><i class="fa fa-refresh"></i> reload</a>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="row bb-2">
                    <div class="col-12 mb-2">
                        <div class="fl-te-c px-lg-3 px-3">
                            <div class="fl-x-cc pos-r">
                                <span class="bullet-2 d-inline-block mr-2"></span>
                                <span class="font-inter-medium fs-lg-2">Auction {{ auction.auction_details.id }}</span>
                                <span class=" mx-3">|</span>
                                <span class="font-inter-medium fs-lg-2"> {{ auction.auction_details.title }}</span>
                            </div>
                            <div class="d-sm-none">
                                <btn v-if="auction.data.status!==''" text="Cancel History" color="secondary"
                                     class="mr-1 mb-4  ml-lg-0 mt-4"
                                     @click="$router.push({path: '/auction/' + auction.auction_details.id + '/cancel-history/'})"/>
                                <btn text="View Participant" color="secondary" class="mr-1 mb-4  ml-lg-0 mt-4"
                                     v-if="auction.data.status === 'Created'"
                                     :disabled="!(currentUser.permission.can_view_bidder || currentUser.is_bank_admin || currentUser.is_superuser)"
                                     @click="$router.push({path: '/auction/' + auction.auction_details.id + '/details/view-participants/'})"/>

                                <btn text="Suspend" class=" ml-lg-0 mb-4 ml-2 mt-4" icon="fa fa-times" type="button"
                                     color="danger"
                                     v-if="auction.data.status === 'Created' && (currentUser.is_superuser || currentUser.is_bank_admin ||
                                            currentUser.permission.can_reject_auction)"
                                     @click="$refs.suspendModal.show()" loading-text="Suspending..."
                                     :loading="suspend"/>
                            </div>

                        </div>
                        <div class="">
                            <div class="d-lg-none">
                                <btn v-if="auction.data.status!==''" text="Cancel History" size="sm" color="secondary"
                                     class="mr-1 mb-4 ml-lg-0 mt-3"
                                     @click="$router.push({path: '/auction/' + auction.auction_details.id + '/cancel-history/'})"/>

                                <btn text="View Participant" size="sm" color="secondary" class="mr-1 mb-4 ml-lg-0 mt-3"
                                     v-if="auction.data.status === 'Created'"
                                     :disabled="!(currentUser.is_bank_admin || currentUser.permission.can_view_bidder || currentUser.is_superuser)"
                                     @click="$router.push({path: '/auction/' + auction.auction_details.id + '/details/view-participants/'})"/>

                                <btn text="Suspend" class=" ml-lg-0 mb-4 ml-2 mt-3" icon="fa fa-times" type="button"
                                     color="danger" size="sm"
                                     v-if="auction.data.status === 'Created' && (currentUser.is_superuser || currentUser.is_bank_admin ||
                                        currentUser.permission.can_reject_auction)"
                                     @click="$refs.suspendModal.show()" loading-text="Suspending..."
                                     :loading="suspend"/>
                            </div>
                            <btn v-if="auction.data.status === 'Ended'" text="Reports"
                                 class="ml-1 mt-2" icon="fa fa-download" type="button"
                                 @click="downloadReport" loading-text="Downloading..." :loading="downloading"/>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-12 py-3">
                        <auction-details-tabs-new :details="auction"/>
                    </div>

                    <div class="col-12 mb-3 mt-3">
                        <div class="fl-x fl-j-c py-3 pos-r">

                            <div v-if="auction.auction_items.length === 0" class="pos-a-r-0 mt-2">
                                <btn icon="fa fa-plus" color="secondary" size="sm" text="Add"
                                     @click="$refs.addModal.show()"
                                     v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_add_auction)
                                            && auction.data.status === ''"/>
                            </div>

                            <div class="btn-group-tight pos-a-r-0 mt-2" v-else>
                                <btn icon="fa fa-pencil" color="secondary"
                                     v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_edit_auction)
                                            && auction.data.status === ''"
                                     @click="setEdit" class=""/>

                                <btn icon="fa fa-trash" color="danger" @click="setItemDelete"
                                     v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_delete_auction)
                                            && auction.data.status === ''"/>
                            </div>
                            <div class="fl-x fl-a-c mt-5 mb-3">
                                <i class="fa fa-database icon-img mr-2" style="color: blue"></i>
                                <span>Auction Items</span>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <section>
                            <div class="mb-3" v-for="(i, k) in auction.auction_items" v-bind:key="k">
                                <div class="card">
                                    <div class="row">

                                        <div class="col-6 col-lg-3 ">
                                            <h6 class="font-inter-medium">Item Name </h6>
                                            <p>{{ i.item_name }}</p>
                                        </div>

                                        <div class="col-6 col-lg-3">
                                            <h6 class="font-inter-medium">Total Items </h6>
                                            <p>{{ i.total_item_numbers }}</p>
                                        </div>

                                        <div class="col-6 col-lg-3 mt-lg-0 mt-3">
                                            <h6 class="font-inter-medium">State </h6>
                                            <p>{{ i.state }}</p>
                                        </div>

                                        <div class="col-6 col-lg-3 mt-lg-0 mt-3">
                                            <h6 class="font-inter-medium">District </h6>
                                            <p>{{ i.district }}</p>
                                        </div>

                                        <div class="col-6 col-lg-3 mt-lg-4 mt-3">
                                            <h6 class="font-inter-medium">Pincode </h6>
                                            <p>{{ i.pincode }}</p>
                                        </div>

                                        <div class="col-6 col-lg-3 mt-lg-4 mt-3">
                                            <h6 class="font-inter-medium">Item Data Sheet </h6>
                                            <p>
                                                <btn v-if="i.item_data_sheet" @click="setDownload(i.item_data_sheet)"
                                                     text="Download" class="btn-xs" icon="fa fa-download"/>

                                                <span v-else>Not Uploaded</span>
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div class="col-12 mt-3">
                        <div class="fl-x fl-j-c fl-a-c py-5 pos-r">

                            <btn icon="fa fa-plus" size="sm" color="secondary" text="Add"
                                 v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_add_auction)
                                    && auction.data.status === ''"
                                 class="pos-a-r-0 mt-2" @click="$refs.addImageModal.show()"></btn>

                            <div class="fl-x fl-a-c pos-r">
                                <img class="icon-img mr-2" src="../../assets/web/auction-page-details/image-symbol.png"
                                     alt="">
                                <span>Property Images</span>
                            </div>

                        </div>
                    </div>

                    <div class="col-12">
                        <section>

                            <div class="d-grid d-grid-res m-0">
                                <CoolLightBox
                                    :items="propertyImagesList"
                                    :index="index"
                                    @close="index = null">
                                </CoolLightBox>

                                <div class="fl-2 cursor-pointer" v-for="(image, id) in auction.property_images"
                                     v-bind:key="id">
                                    <div class="pos-r w-100 h-100">
                                        <img class="img-fluid w-100 h-100" @click="index = id" :src="image.image"
                                             :alt="'#PropertyImage' + id">

                                        <btn icon="fa fa-trash" @click="setDeleteImage(image)" border-radius="0"
                                             class="pos-a-r-0"
                                             v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_delete_auction)
                                                    && auction.data.status === ''"></btn>

                                    </div>
                                </div>
                            </div>

                            <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="addImageModal"
                                   width="30r"
                                   header-color="">
                                <add-auction-image @success="formSuccess"/>
                            </modal>

                        </section>

                    </div>

                    <div class="col-12 mb-3 mt-3">
                        <div class="fl-x fl-j-c py-3 pos-r">

                            <btn icon="fa fa-plus" size="sm" color="secondary" text="Add"
                                 @click="$refs.addVideoModal.show()"
                                 v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_add_auction)
                                        && auction.data.status === ''"
                                 class="pos-a-r-0 mt-2"/>

                            <div class="fl-x fl-a-c">
                                <img class="icon-img mr-2" src="../../assets/web/auction-page-details/video-symbol.png"
                                     alt="">
                                <span>Property Videos</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <section class="mb-5 row">

                            <div class="col-12 col-md-6 col-lg-4 cursor-pointer"
                                 v-for="(video, id) in auction.property_videos"
                                 v-bind:key="id">

                                <div class="px-lg-4 px-2 text-white card-contain bg-cover bg-pos-c bg-4"
                                     :style="{'background-image':'url('+ video.video_thumbnail.image +')'}">
                                    <!--                            <div class="text-white card-contain bg-contain bg-4">-->
                                    <!--                                <img :src="video.video_thumbnail.image" class="img-fluid h-100" alt="">-->
                                    <btn icon="fa fa-trash" border-radius="0" class="pos-a-r-1"
                                         @click="setDeleteVideo(video)"
                                         v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_delete_auction)
                                                && auction.data.status === ''"></btn>

                                    <div class="dark-overlay"></div>

                                    <div @click="forceDownload(video.video)"
                                         class="fl-y fl-a-c fl-j-e card-text video-item" style="cursor: pointer">
                                        <!--                                    <div class="fl-x fl-a-c w-100">-->
                                        <div class="fl-x fl-a-c w-100 play-btn">
                                            <i class="fa fa-play text-white"></i>
                                            <div>Play</div>
                                        </div>
                                    </div>

                                    <div class="fa-3x icon-center"></div>
                                </div>

                            </div>

                        </section>

                        <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="addVideoModal"
                               width="30r"
                               header-color="">
                            <add-property-video @success="formSuccess"/>
                        </modal>

                    </div>

                    <div v-show="false" class="col-12 my-4">
                        <div class="fl-x fl-j-c">
                            <div class="fl-x-res">

                                <auction-download-button block text="Request Property Data Sheet">
                                    <template #image>
                                        <img src="../../assets/web/auction-page-details/request-data-sheet.png"
                                             class="img-fluid m-0"
                                             alt="">
                                    </template>
                                </auction-download-button>

                                <auction-download-button text="Get Legal Advisory Support">
                                    <template #image>
                                        <img src="../../assets/web/auction-page-details/support.png"
                                             class="img-fluid m-0"
                                             alt="">
                                    </template>
                                </auction-download-button>

                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <map-component :items="auction.auction_items"></map-component>
                    </div>

                    <div class="col-12 mt-4">
                        <div class="fl-x fl-j-c">
                            <div class="btn-group">
                                <btn @click="$router.go(-1)" color="secondary"
                                     class="bg-secondary-600 px-5" text="Back"></btn>

                                <btn v-if="auction.data.status === '' &&
                                (currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_approve_auction)"
                                     text="Publish" :loading="saving"
                                     loading-text="Publishing..." class="px-5"
                                     design="basic-a" color="secondary" @click="showPublishPopUp"></btn>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <modal title="Confirm Publishing Auction" ref="confirmPublishAuctionModal"
                   :no-close-on-backdrop="true" width="35r">
                <p>You are about to publish the auction, Are you sure ?</p>
                <div class="fl-x-cr">
                    <btn size="sm" @click="$refs.confirmPublishAuctionModal.close()" color="danger" text="Cancel"/>
                    <btn size="sm" color="secondary" class="bg-secondary-600 px-5 ml-2" @click="publishAuction"
                         text="Publish" :loading="saving" loading-text="Publishing..."/>
                </div>
            </modal>

            <delete-modal ref="deleteImageModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the property image <b v-html="deletingItem && deletingItem.name"></b>. Are
                    you
                    sure ?</p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>

            <delete-modal ref="deleteVideoModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the property video <b v-html="deletingItem && deletingItem.name"></b>. Are
                    you
                    sure ?</p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>

            <modal title="Auction Item Add" ref="addModal" :no-close-on-backdrop="true" width="60r"
                   header-color="primary">
                <add-auction-item @success="formSuccess" :is_third_party="auction.auction_details.is_third_party"
                                  @cancelOperation="$refs.editModal.close();"/>
            </modal>

            <modal title="Auction Item Update" ref="editModal" :no-close-on-backdrop="true" width="60r"
                   header-color="primary">

                <edit-auction-item :initial-data="editingItem" @success="formSuccess"
                                   :is_third_party="auction.auction_details.is_third_party"
                                   @cancelOperation="$refs.editModal.close();"/>
            </modal>

            <modal title="Suspend Reason" ref="suspendModal" :no-close-on-backdrop="true" width="50r"
                   header-color="primary">

                <suspend-auction @success="suspendAuction"/>
            </modal>

            <delete-modal ref="deleteItemModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the Item <b v-html="deletingItem && deletingItem.item_name"></b>. Are you
                    sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.item_name"></b>.
                </template>
            </delete-modal>

        </inner-page-card>
    </div>
</template>

<script>
import propertyVideoImg from '../../assets/web/auction-page-details/video.jpg';
import AuctionDetailsTabsNew from '../../components/auction/auction-details/AuctionDetailsTabsNew';
import AuctionDownloadButton from '@components/auction/auction-details/AuctionDownloadButton';
import MapComponent from '@components/auction/auction-details/MapComponent';

import SuspendAuction from '@/views/auction/suspend-auction/SuspendAuction';

import AddAuctionItem from '../../views/auction/auction-items/AddAuctionItem';
import EditAuctionItem from '../../views/auction/auction-items/EditAuctionItem';

import AddAuctionImage from '../../views/auction/add-image/AddAuctionImage';
import AddPropertyVideo from '../../views/auction/add-video/AddPropertyVideo';

import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'AuctionDetailsPage',

    computed : {
        ...mapGetters(['currentUser']),

        getContainerClass () {
            if (this.currentUser.is_premium_customer || this.index === 0) {
                return 'px-lg-4 px-2 text-white card-contain bg-cover bg-4';
            } else if (!this.currentUser.is_premium_customer && this.index !== 0) {
                return 'px-lg-4 px-2 text-white card-contain bg-cover bg-4 blur-item';
            } else {
                return 'px-lg-4 px-2 text-white card-contain bg-cover bg-4';
            }
        }
    },

    components : {

        MapComponent,

        AuctionDownloadButton,
        AuctionDetailsTabsNew,

        AddAuctionImage,
        AddPropertyVideo,

        SuspendAuction,

        AddAuctionItem,
        EditAuctionItem
    },

    mounted () {
        this.loadData();
    },

    data () {
        return {
            index            : null,
            propertyVideoImg : propertyVideoImg,

            error       : false,
            saving      : false,
            loading     : true,
            suspend     : false,
            downloading : false,

            auction : null,

            editingItem  : null,
            deletingItem : null,
            deleteUrl    : null,

            deleteImageUrl     : urls.auction.auction.auctionImage.delete,
            deleteVideoUrl     : urls.auction.auction.auctionVideo.delete,
            deleteItemUrl      : urls.auction.auction.auctionItem.delete,
            propertyImages     : null,
            propertyImagesList : [],
            propertyVideosList : []

        };
    },

    methods : {

        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$refs.addImageModal.close();
            this.$refs.addVideoModal.close();
            this.loadData();
        },

        setEdit () {
            this.editingItem = { ...this.auction.auction_items[0] };
            this.editingItem.auction = this.editingItem.auction_id;
            this.$refs.editModal.show();
        },

        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            const model = {
                auction : that.$route.params.id
            };
            axios.form(urls.auction.auction.info, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.auction = { ...json.data };
                    that.loading = false;
                    console.log('that.auction : ', that.auction);
                    that.auction.property_images.forEach(function (item) {
                        that.propertyImagesList.push(item.image);
                    });
                    // store propertyVideos
                    // that.auction.property_videos.forEach(function (item) {
                    //     this.addToPropertyVideosList(item);
                    // });
                } else {
                    that.loading = false;
                    that.error = true;
                }
            }).catch(function (exception) {
                console.log('exception at the incident details loading => ', exception);
                that.loading = false;
                that.error = true;
            });
        },

        setPropertyImagesList () {
            const that = this;
            this.propertyImages.forEach(function (item) {
                that.propertyImagesList.push(item.image);
            });
        },

        setDeleteImage (imageItem) {
            this.deleteUrl = this.deleteImageUrl;
            this.deletingItem = { ...imageItem };
            this.$refs.deleteImageModal.show();
        },

        setDeleteVideo (videoItem) {
            this.deleteUrl = this.deleteVideoUrl;
            this.deletingItem = { ...videoItem };
            this.$refs.deleteVideoModal.show();
        },

        setItemDelete () {
            this.deleteUrl = this.deleteItemUrl;
            this.deletingItem = { ...this.auction.auction_items[0] };
            this.$refs.deleteItemModal.show();
        },

        deleteComplete () {
            this.$refs.deleteImageModal.close();
            this.$refs.deleteVideoModal.close();
            this.$refs.deleteItemModal.close();
            this.$notify('Successfully deleted', 'Success', { type : 'success' });
            this.deletingItem = null;
            this.deleteUrl = null;
            this.loadData();
        },

        setDownload (url) {
            window.open(url);
        },

        forceDownload (url) {
            window.open(url);
        },

        showPublishPopUp () {
            this.$refs.confirmPublishAuctionModal.show();
        },

        publishAuction () {
            const that = this;
            that.saving = true;

            axios.form(urls.auction.auction.publish, { id : that.$route.params.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Published Auction', 'Success', { type : 'success' });
                    that.saving = false;
                    that.$refs.confirmPublishAuctionModal.close();
                    that.$router.push('/auctions-list/');
                } else {
                    that.$notify(json.message, 'Warning', { type : 'warning' });
                    that.saving = false;
                }
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.$notify('Server Error', 'Error', { type : 'danger' });
                that.saving = false;
            });
        },

        async downloadReport () {
            const that = this;
            this.downloading = true;
            const response = await axios.form(urls.auction.auction.live.reportDownload,
                { auction_id : this.$route.params.id }).catch(function (exception) {
                console.log('exception : ', exception);
                that.$notify('Could not download the auction, Please try again later', 'Failed', {
                    type : 'danger'
                });
                that.downloading = false;
            });
            const json = response.data;
            if (json.error === false) {
                window.open(json.url);
                this.$notify('Successfully downloaded the report.', 'Success', {
                    type : 'success'
                });
            } else {
                this.$notify('Could not download the auction, Please try again later', 'Failed', {
                    type : 'danger'
                });
            }
            this.downloading = false;
        },

        suspendAuction (reason) {
            const that = this;

            that.$refs.suspendModal.close();

            that.suspend = true;

            const model = {
                auction          : that.$route.params.id,
                suspended_reason : reason.suspended_reason
            };

            axios.form(urls.auction.auction.suspend, { ...model }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.suspend = false;
                    that.$notify(json.message, 'Success', {
                        type : 'success'
                    });
                    that.loadData();
                } else {
                    that.suspend = false;
                    that.$notify(json.message, 'Success', {
                        type : 'success'
                    });
                }
            }).catch(function (exception) {
                that.suspend = false;
                console.log('exception occurred at the suspend the auction', exception);
                that.$notify('Something went wrong, Please try after some time.', 'Error', {
                    type : 'danger'
                });
            });
        },
        addToPropertyVideosList (item) {
            console.log('video-item', item);
        }

    }
};
</script>

<style scoped lang="scss">

.bullet {
    width: 0.6rem;
    height: 0.6rem;
    background-color: var(--bg-10) !important;
    border: 1px solid gray;
    border-radius: 50%;
}

.vertical-line {
    height: 1.2rem;
    width: 2px;
    background-color: rgba(128, 128, 128, 1);
}

.line-bottom-1 {
    height: 3px;
    width: 100%;
    /*background-color: rgba(255);*/
}

.bg-blue-layer {
    background: url("../../assets/web/bg/blue-strip-alternate.jpg");
}

.c-margin-x {
    margin: -15px -15px 0 -15px;
}

.icon-md {
    height: 2.5rem !important;
}

.bullet-2 {
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: var(--color-secondary);
}

/*.video-item {*/
/*    min-height: 11rem !important;*/
/*}*/

.pos-a-r-1 {
    position: absolute;
    top: 0;
    right: 0.934rem;
}

.bg-contain {
    background-size: contain;
}

.bg-cover {
    background-size: cover;
}

.bg-pos-c {
    background-position: center;
}

.clearfix {
    overflow: auto;
    float: right;
    display: inline-block;
    width: 50%;
}

.play-btn {
    position: absolute;
    bottom: 1rem;
    left: 2rem !important;
}

.card-contain {
    position: relative;
    min-height: 20vh;
    margin-bottom: var(--spacer-3);
    /*min-height: 420px;*/
    @media (min-width: 992px) {
        /*min-height: 30vh;*/
        min-height: 15rem !important;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 18vh;
    }
}
</style>
