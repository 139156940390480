<template>
    <div>
        <div class="card-1 row bs-10 m-0 font-inter-medium">
            <h5 class="head-1 py-3 p-2 pl-4">Event Details</h5>
            <div class="tab-details-contain pos-r">

                <div v-for="(item,i) in Object.keys(details.event_details)" class="w-100 item-bg" :key="'event-' + i">

                    <div class="cell-item " v-if="item === 'id'"></div>

                    <div class="cell-item " v-else-if="item === 'event_bank_details'"></div>

                    <div class="cell-item " v-else-if="item === 'event_bank'"></div>

                    <div class="cell-item" v-else>
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e fl-a-s">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-3">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                                    <span class="fs-data-item">
                                        <b>{{ details.event_details[item] }}</b>
                                    </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-1 row  bs-10 mt-lg-4 m-0 font-inter-medium">
            <h5 class="head-1 py-3 p-2 pl-4">Property Details</h5>
            <div class="tab-details-contain pos-r">

                <!--                    <btn icon="fa fa-pencil" color="secondary" text="Edit" class="pos-a-r-0"></btn>-->

                <div class="w-100 item-bg" v-for="(item,i) in Object.keys(details.property_details)" :key="'prop-' + i">

                    <div class="cell-item" v-if="item === 'id'"></div>

                    <div class="cell-item" v-else>
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e fl-a-s">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-3">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                                <span class="fs-data-item">
                                    <b>{{ details.property_details[item] }}</b>
                                </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-1 row  bs-10 mt-lg-4 m-0 font-inter-medium">
            <h5 class="head-1 py-3 p-2 pl-4">Auction Details</h5>
            <div class="tab-details-contain pos-r">
                <!--                    <btn icon="fa fa-pencil" color="secondary" text="Edit" class="pos-a-r-0"></btn>-->

                <div class="w-100 item-bg" v-for="(item, i) in Object.keys(details.auction_details)"
                     :key="'auction-' + i">

                    <div class="cell-item" style="display: none" v-if="item === 'auction_id'"></div>
                    <div class="cell-item" style="display: none" v-else-if="item === 'auction_key'"></div>
                    <div class="cell-item" style="display: none" v-else-if="item === 'user_status'"></div>

                    <div class="" v-else-if="item === 'id'">
                        <div class="cell-item">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="fl-x fl-j-e fl-a-s">
                                    <div class="data-label text-uppercase capitalize-string">Auction Id</div>
                                    <span class="mx-3">:</span>
                                </div>
                            </div>
                            <div class="fl-x fl-j-s item-min-width">
                                    <span class="fs-data-item">
                                        <b>{{ details.auction_details[item] }}</b>
                                    </span>
                            </div>
                        </div>
                    </div>

                    <div class="" v-else-if="!isTimeField(item)">
                        <div class="cell-item">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="fl-x fl-j-e fl-a-s">
                                    <div class="data-label text-uppercase capitalize-string">{{
                                        setHeading(item)
                                        }}
                                    </div>
                                    <span class="mx-3">:</span>
                                </div>
                            </div>
                            <div class="fl-x fl-j-s item-min-width">
                                    <span class="fs-data-item">
                                        <b class="fl-x">
                                            <span
                                                v-if="(item === 'reserve_price' || item === 'tender_fee'
                                                || item === 'price_bid' || item === 'increment_value')
                                                 && details.auction_details[item] !== 'Applicable'">₹</span>
                                            {{ details.auction_details[item] }}
                                            <span v-if="item === 'auto_extension_time'"> Mins</span>
                                            <p class="mb-0 ml-2 font-weight-normal"
                                               v-if="item === 'auction_start_date'">{{
                                                    details.auction_details['auction_start_time']
                                                }}</p>
                                            <p class="mb-0 ml-2 font-weight-normal" v-if="item === 'auction_end_date'">{{
                                                    details.auction_details['auction_end_time']
                                                }}</p>
                                        </b>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center text-primary font-inter-medium my-3 fs-1">EMD Details</div>

                <div class="w-100 item-bg" v-for="(item,i) in Object.keys(details.emd_details)" :key="'emd-' + i">

                    <div class="cell-item" style="display: none" v-if="item === 'id'"></div>

                    <div class="cell-item" v-else>
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e fl-a-s">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-3">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                                <span class="fs-data-item">
                                    <b><span v-if="item === 'emd_amount'">₹</span> {{ details.emd_details[item] }}</b>
                                </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-1 row  bs-10 mt-lg-4 m-0 font-inter-medium">
            <h5 class="head-1 py-3 p-2 pl-4">Important Dates & Times</h5>
            <div class="tab-details-contain pos-r">

                <!--                    <btn icon="fa fa-pencil" color="secondary" text="Edit" class="pos-a-r-0 mt-2"></btn>-->

                <div class="w-100 item-bg" v-for="(item,i) in Object.keys(details.important_dates)" :key="'date-' + i">

                    <div class="cell-item " v-if="item === 'id'"></div>
                    <div class="" v-else-if="item === 'auction_end_date_counter_format'"></div>

                    <div class="cell-item" v-else-if="!isTimeField(item)">
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e fl-a-s">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-3">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                                <span class="fs-data-item">
                                    <b class="fl-x">
                                       <p class="m-0"> {{ details.important_dates[item] }} </p>
                                        <p class="mb-0 ml-2 font-weight-normal"
                                           v-if="item === 'auction_start_date'">{{
                                                details.important_dates['auction_start_time']
                                            }}</p>
                                        <p class="mb-0 ml-2 font-weight-normal"
                                           v-if="item === 'auction_end_date'">{{
                                                details.important_dates['auction_end_time']
                                            }}</p>
                                        <p class="mb-0 ml-2 font-weight-normal"
                                           v-if="item === 'press_release_date'">{{
                                                details.important_dates['press_release_time']
                                            }}</p>
                                        <p class="mb-0 ml-2 font-weight-normal"
                                           v-if="item === 'property_inspection_from_date'">{{
                                                details.important_dates['property_inspection_from_time']
                                            }}</p>
                                        <p class="mb-0 ml-2 font-weight-normal"
                                           v-if="item === 'property_inspection_to_date'">{{
                                                details.important_dates['property_inspection_to_time']
                                            }}</p>
                                        <p class="mb-0 ml-2 font-weight-normal"
                                           v-if="item === 'bid_submission_last_date'">{{
                                                details.important_dates['bid_submission_last_time']
                                            }}</p>
                                        <p class="mb-0 ml-2 font-weight-normal"
                                           v-if="item === 'bid_opening_date'">{{
                                                details.important_dates['bid_opening_time']
                                            }}</p>
                                    </b>
                                </span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="card-1 row  bs-10 mt-lg-4 m-0 font-inter-medium">
            <h5 class="head-1 py-3 p-2 pl-4">Auction Related Documents</h5>
            <div class="tab-details-contain pos-r">
                <!--                    <btn icon="fa fa-pencil" color="secondary" text="Edit" class="pos-a-r-0"></btn>-->

                <div class="item-bg my-2 my-lg-0" v-for="(item, key) in Object.keys(details.document_details)"
                     v-bind:key="'doc-' + key">

                    <div v-if="item === 'id'"></div>
                    <div v-else-if="item === 'pancard'"></div>

                    <div class="cell-item item-bg" v-else>
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e fl-a-s">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-3">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                            <span class="" v-if="item !== 'documents_to_be_uploaded'">
                                <btn text="Download" size="xs" icon="fa fa-download" type="button"
                                     @click="setDownload(details.document_details[item])"/>
                            </span>
                            <span class="" v-else>
                                Pan Card, Annexure 3/Declaration Form, Annexure 2/Details of Bidder, Proof Of Address
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'AuctionDetailsTabsNew',

    props : {
        details : {
            type : Object
        },

        item : {
            type : Object,
            default () {
                return {
                    name  : '',
                    value : ''
                };
            }
        }
    },
    data () {
        return {
            timeFieldItems : ['auction_start_time',
                'auction_end_time',
                'press_release_time',
                'property_inspection_from_time',
                'property_inspection_to_time',
                'bid_submission_last_time',
                'bid_opening_time']
        };
    },

    methods : {
        setDownload (url) {
            window.open(url);
        },

        setHeading (title) {
            return title.replaceAll('_', ' ');
        },
        isTimeField (item) {
            return this.timeFieldItems.includes(item);
        }
    }

};
</script>

<style scoped lang="scss">
    .head-1 {
        color: white;
        background-color: #204bb8;
        text-align: center;
        //width: 64rem;
        width: 100%;
    }

    .cell-item {
        display: flex;
        //justify-content: space-between;
        justify-content: center;
        align-items: start;
        font-size: 0.9rem;
        @media (min-width: 992px) {
            padding: 0.875rem 0.3125rem;
            width: 50%;
            font-size: 0.9rem;
        }
        //border-left: 1px solid gray;
        //border-right: 1px solid gray;
        //border-top: 1px solid gray;
        position: relative;
        width: 100% !important;
    }

    .item-min-width {
        min-width: 50% !important;
        @media (min-width: 992px) {
            min-width: 54% !important;
            //min-width: 16rem;
            width: 18rem;
        }
    }

    .data-label {
        font-size: 0.7rem;
        text-align: right;
        width: 80%;
        @media (min-width: 992px) {
            font-size: 1.2rem;
            width: 100%;
        }
        font-weight: 400;
        //display: inline-flex;
        //width: 70%;
        //min-width: 7rem;
    }

    .value-item b {
        font-size: 18px;
    }

    .tab-details-contain {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .item-bg {
            width: 100% !important;

            &:nth-child(even) {
                background-color: var(--bg-10) !important;
            }
        }
    }

    .fs-data-item {
        font-size: 0.6rem;
        @media (min-width: 992px) {
            font-size: 1.2rem;
            max-width: 55%;
        }
    }
</style>
