<template>
    <div class="row pos-r">

        <div class="col-12 col-md-4 m-0 p-0">
            <div class="bg-primary fl-y-cc map-min-h">
                <span><i class="fa fa-map-marker fa-2x"></i></span>
                <p>Property Location</p>
            </div>
        </div>

        <div class="col-12 col-md-8 m-0 p-0 pos-r">

            <iframe v-if="lat && lang" class="w-100 h-100" id="gmap_canvas_1"
                :src="'https://maps.google.com/maps?q=' + lat + ',' + lang + '&hl=es;z=14&amp;output=embed'"></iframe>

            <iframe v-else class="w-100 h-100" id="gmap_canvas"
                    src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
    </div>
</template>

<script>

export default {
    name : 'MapComponent',

    props : {
        items : {
            type : Array
        }
    },

    data () {
        return {
            lat  : '',
            lang : ''
        };
    },

    mounted () {
        this.setLatAndLang();
    },

    methods : {
        setLatAndLang () {
            if (this.items) {
                const item = this.items[0];

                this.lat = item.item_location_lattitude;
                this.lang = item.item_location_longitude;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.mapouter {
    position: relative;
    text-align: right;
    height: 500px;
    width: 600px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 600px;
}

.map-min-h {
    min-height: 20vh;
    @media (min-width: 992px) {
        min-height: 40vh;
    }
}
</style>
